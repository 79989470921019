<template>
  <div class="modal-content px-8 dark:bg-gray-600">
    <form
      class="w-full max-w-xl"
      @submit.prevent="onSubmit"
    >
      <div class="md:flex md:items-center mb-4">
        <div class="md:w-1/3">
          <label
            class="block text-sm font-medium leading-5 text-gray-700 text-right mr-4"
            for="inline-current-password"
          >
            Current Password
          </label>
        </div>
        <div class="mt-1 relative rounded-md shadow-sm md:w-2/3">
          <input
            id="inline-current-password"
            v-model="passwordForm.current_password"
            required
            class="block w-full border border-gray-300 rounded-md focus:border-blue-300 focus:ring focus:ring-blue-200 focus:ring-opacity-50 sm:text-sm sm:leading-5"
            type="password"
            value=""
          >
        </div>
      </div>

      <div
        v-show="passwordForm.errors.has('current_password')"
        class="text-red-500 text-sm italic mb-4 px-2"
      >
        {{ passwordForm.errors.get('current_password') }}
      </div>

      <div class="md:flex md:items-center mb-4">
        <div class="md:w-1/3">
          <label
            class="block text-sm font-medium leading-5 text-gray-700 text-right mr-4"
            for="inline-new-password"
          >
            New Password
          </label>
        </div>
        <div class="mt-1 relative rounded-md shadow-sm md:w-2/3">
          <input
            id="inline-new-password"
            v-model="passwordForm.password"
            required
            class="block w-full border border-gray-300 rounded-md focus:border-blue-300 focus:ring focus:ring-blue-200 focus:ring-opacity-50 sm:text-sm sm:leading-5"
            type="password"
          >
        </div>
      </div>

      <div
        v-show="passwordForm.errors.has('password')"
        class="text-red-500 text-sm italic mb-4 px-2"
      >
        {{ passwordForm.errors.get('password') }}
      </div>

      <div class="md:flex md:items-center mb-4">
        <div class="md:w-1/3">
          <label
            class="block text-sm font-medium leading-5 text-gray-700 text-right mr-4"
            for="inline-confirm-password"
          >
            Confirm New Password
          </label>
        </div>
        <div class="mt-1 relative rounded-md shadow-sm md:w-2/3">
          <input
            id="inline-confirm-password"
            v-model="passwordForm.password_confirmation"
            required
            class="block w-full border border-gray-300 rounded-md focus:border-blue-300 focus:ring focus:ring-blue-200 focus:ring-opacity-50 sm:text-sm sm:leading-5"
            type="password"
          >
        </div>
      </div>

      <div
        v-show="passwordForm.errors.has('password_confirmation')"
        class="text-red-500 text-sm italic mb-4 px-2"
      >
        {{ passwordForm.errors.get('password_confirmation') }}
      </div>

      <div
        v-show="passwordForm.errors.has('form')"
        class="text-red-500 text-sm italic mb-4 px-2"
      >
        {{ passwordForm.errors.get('form') }}
      </div>
      <div class="md:flex md:items-center">
        <div class="md:w-1/3" />
        <div class="md:w-2/3">
          <button
            v-show="passwordForm.busy"
            class="btn"
            type="button"
          >
            <fa-icon
              icon="spinner"
              spin
            />
          </button>
          <button
            v-show="!passwordForm.busy"
            class="btn primary"
            type="button"
            @click.prevent="onSubmit"
          >
            Save
          </button>

          <div
            v-if="showSuccess"
            class="text-green-500 italic my-4 px-2"
          >
            Password Updated
          </div>
        </div>
      </div>
    </form>
  </div>
</template>
<script>
export default {
  name: 'ProfileSecurity',

  data () {
    return {
      passwordForm: new SparkForm({
        current_password: '',
        password: '',
        password_confirmation: ''
      }),
      showSuccess: false
    }
  },

  computed: {
    user: function () {
      return this.$store.getters.user
    }
  },

  methods: {

    onSubmit () {
      this.showSuccess = false

      if ((this.passwordForm.current_password === '' || this.passwordForm.current_password === undefined) ||
        (this.passwordForm.password === '' || this.passwordForm.password === undefined) ||
        (this.passwordForm.password_confirmation === '' || this.passwordForm.password_confirmation === undefined)
      ) {
        this.passwordForm.setErrors({ 'form': ['All the fields are required'] })
      } else {
        this.updatePassword()
      }
    },

    updatePassword () {
      this.passwordForm.startProcessing()
      this.$store.dispatch(
        'updatePassword',
        {
          currentPassword: this.passwordForm.current_password,
          newPassword: this.passwordForm.password,
          newPasswordConfirmation: this.passwordForm.password_confirmation
        }
      )
        .then(() => {
          this.showSuccess = true
          this.passwordForm.current_password = ''
          this.passwordForm.password = ''
          this.passwordForm.password_confirmation = ''
          this.passwordForm.finishProcessing()
          alert.success('Password updated')
        })
        .catch(response => {
          this.passwordForm.setErrors(response.body)
        })
    }
  }
}
</script>
